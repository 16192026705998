<template>
    <modal-page-template title="Erreur 403" message="Vous n'avez pas le droit d'accéder à cette page.">
        <b-btn :to="{ name: 'homepage' }" variant="info" class="mb-1">Retour à l'accueil</b-btn>
    </modal-page-template>
</template>

<script>
    const ModalPageTemplate = () => import('@/components/templates/ModalPageTemplate');

    export default {
        name: 'Forbidden',
        components: {ModalPageTemplate}
    };
</script>
